import { Box, Stack, css } from '@mui/system';

import { isChVersion } from '../../utils';

import { AuthMenu } from './AuthMenu';
import { DesktopHeaderMenu } from './DesktopHeaderMenu';

type WebLayoutAppBarActionsProps = {
  strapiMenu?: boolean;
};

export const WebLayoutAppBarActions = ({
  strapiMenu,
}: WebLayoutAppBarActionsProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {strapiMenu && !isChVersion() && (
        <DesktopHeaderMenu
          css={(theme) => css`
            display: none;

            ${theme.breakpoints.up('md')} {
              display: flex;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            }
          `}
        />
      )}

      <Box sx={{ mr: { xs: '-12px !important', md: 0 } }}>
        <AuthMenu />
      </Box>
    </Stack>
  );
};
