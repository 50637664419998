import { useTheme } from '@emotion/react';
import { Types, getStrapiMenu } from '@scorenco/core';
import { StrapiMenu } from '@scorenco/core/src/data/strapi/useStrapiMenuGet';
import {
  DefaultLayout,
  DefaultLayoutOptions,
  GetLayout,
  GetServerSidePropsWithPrevious,
  NavigationBurgerDrawer,
} from '@scorenco/next';
import NextNProgress from 'nextjs-progressbar';

import { Footer } from '../../components';
import { getConfiguration } from '../../utils';

import { Configuration } from './Configuration';
import { MobileHeaderMenu } from './MobileHeaderMenu';
import { WebLayoutAppBarActions } from './WebLayoutAppBarActions';

const ProgressBar = () => {
  const theme = useTheme();

  return <NextNProgress color={theme.vars.palette.secondary.main} />;
};

const getLayout = (): GetLayout => (page) => (
  <>
    <Configuration />
    <ProgressBar />
    <NavigationBurgerDrawer noPermanent>
      <MobileHeaderMenu />
    </NavigationBurgerDrawer>
    {page}
    <Footer />
  </>
);

export type WebLayoutPageProps = {
  menu: StrapiMenu;
  footer: StrapiMenu;
  configuration: {
    mainMessage?: string;
  };
};

const serverSideProps: GetServerSidePropsWithPrevious = async () => {
  const menu = await getStrapiMenu('main');
  const footer = await getStrapiMenu('footer');
  const configuration = await getConfiguration(Types.PublicationState.Live);

  return {
    props: {
      menu,
      footer,
      configuration,
    },
  };
};

export const WebLayout = {
  ...DefaultLayout,

  buildGetServerSideProps: (
    options?: DefaultLayoutOptions,
    list?: GetServerSidePropsWithPrevious[]
  ) =>
    DefaultLayout.buildGetServerSideProps(options, [
      serverSideProps,
      ...(list || []),
    ]),

  buildGetLayout: (
    options?: DefaultLayoutOptions & { strapiMenu?: boolean },
    getLayouts?: GetLayout[]
  ) => {
    return DefaultLayout.buildGetLayout(
      {
        ...(options || {}),
        navigation: {
          AppBarActions: () => (
            <WebLayoutAppBarActions strapiMenu={!!options?.strapiMenu} />
          ),
          ...(options?.navigation || {}),
        },
        amplify: {
          protectedPage: false,
          ...(options?.amplify || {}),
        },
      },
      [getLayout(), ...(getLayouts || [])]
    );
  },
};
