import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { useIsSsr } from '@scorenco/components';
import {
  AuthentificationStatus,
  RouteLink,
  useAmplifyServiceContext,
  useTranslation,
} from '@scorenco/core';

import { T_KEYS } from '../../../translations';

export const AuthMenu = () => {
  const { t } = useTranslation();
  const isSsr = useIsSsr();
  const { status } = useAmplifyServiceContext();

  if (isSsr || status === AuthentificationStatus.Loading) {
    return null;
  }

  if (status === AuthentificationStatus.Authenticated) {
    return (
      <RouteLink href={process.env.NEXT_PUBLIC_MOBILE_URL}>
        <Button component="a" color="secondary" variant="contained">
          Tableau de bord
        </Button>
      </RouteLink>
    );
  }

  return (
    <Stack gap={2} direction="row" justifyContent="center" alignItems="center">
      <RouteLink
        href={`${process.env.NEXT_PUBLIC_MOBILE_URL}/auth/select-sport`}
      >
        <Button component="a" color="secondary" variant="contained">
          {t(T_KEYS.AUTHENTIFICATION_SIGN_UP)}
        </Button>
      </RouteLink>
      <RouteLink href={`${process.env.NEXT_PUBLIC_MOBILE_URL}/auth/sign-in`}>
        <Button component="a" color="secondary" variant="outlined">
          {t(T_KEYS.AUTHENTIFICATION_SIGN_IN)}
        </Button>
      </RouteLink>
    </Stack>
  );
};
